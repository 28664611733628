:root {
	--backgroundDark: #1f1f1f;
	--outlineLight: #3f3f3f;
	--outlineDark: #7f7f7f;
	--borderLight: #3f3f3f;
	--borderDark: #7f7f7f;
	--selectDark: #3f3f3f;
	--textDark: #cfcfcf;
	--siaLight: #1f1f1f;
	--siaDark: #cfcfcf;
}

*, *::before, *::after {
	box-sizing: border-box;
}

html {
	font-size: 32px;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
	'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
	sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
    min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
	monospace;
}

@media screen and (max-width: 3440px) {

	html {
		font-size: 28px;
	}
}

@media screen and (max-width: 2560px) {

	html {
		font-size: 20px;
	}
}

@media screen and (max-width: 1920px) {

	html {
		font-size: 16px;
	}
}