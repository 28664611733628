.logo-link {
	text-decoration: none;
	border-radius: 0.25rem;
}

.logo-link:focus {
	outline: 2px solid var(--outlineLight);
}

.logo-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0.25rem;
}

.logo-image {
	width: 5rem;
	height: 5rem;
}

.logo-text {
	margin-left: 0.5rem;
	font-size: 2.5rem;
	color: #7f8c8d;
}

@media screen and (max-width: 1000px) {
	.logo-image {
		width: 3rem;
		height: 3rem;
	}

	.logo-text {
		font-size: 1.5rem;
	}
}